import { render } from "./index.vue?vue&type=template&id=f3a85fa2"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "f3a85fa2"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('f3a85fa2', script)) {
    api.reload('f3a85fa2', script)
  }
  
  module.hot.accept("./index.vue?vue&type=template&id=f3a85fa2", () => {
    api.rerender('f3a85fa2', render)
  })

}

script.__file = "src/views/test/index.vue"

export default script